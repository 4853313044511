import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import logo from "../images/logo.png";
import profile from "../images/profile.png";
import camera from "../images/Camera.png";
import classes from "./selftest.module.css"; // Ensure your CSS file is imported
import axios from "axios";
import { UserContext } from "../context/context";

import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";
import "@tensorflow/tfjs-backend-webgl";

import { useMediaQuery } from 'react-responsive';

const SelfTest = () => {
  const isDesktop = useMediaQuery({ minWidth: 1440 });
  const isTablet = useMediaQuery({ minWidth: 912, maxWidth: 1439 });
  const isMobile = useMediaQuery({ maxWidth: 911 });

  const navigate = useNavigate();
  const [isRecording, setIsRecording] = useState(false);
  const [timer, setTimer] = useState(10);
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [screenshot, setScreenshot] = useState(null);
  const videoRef = useRef(null)
  const webcamRef = useRef(null);
  const webcamRefTab = useRef(null);
  const webcamRefMob = useRef(null);
  const { currGen, setCurrGen } = useContext(UserContext);

  const [loader, setLoader] = useState(false)
  
  const [model, setModel] = useState(null);
  const [isDetecting, setIsDetecting] = useState(false);

  useEffect(() => {
    const loadModel = async () => {
      const detectorModel = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
      const detectorConfig = {
        runtime: "mediapipe",
        solutionPath: "https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh",
      };
      const detector = await faceLandmarksDetection.createDetector(detectorModel, detectorConfig);
      setModel(detector);
    };

    loadModel();
  }, []);
    
  const isFaceInCircularFrame = (face, videoWidth, videoHeight) => {
    const centerX = videoWidth / 2;
    const centerY = videoHeight / 2;
    const radius = videoWidth / 2; // Circular frame radius

    const faceBox = face.box; // Bounding box of the face
    const faceCenterX = faceBox.xMin + (faceBox.xMax - faceBox.xMin) / 2;
    const faceCenterY = faceBox.yMin + (faceBox.yMax - faceBox.yMin) / 2;

    // Calculate the distance from the face center to the video center
    const distanceFromCenter = Math.sqrt(
      Math.pow(faceCenterX - centerX, 2) + Math.pow(faceCenterY - centerY, 2)
    );

    // Check if the face is within the circular region
    return distanceFromCenter + (faceBox.xMax - faceBox.xMin) / 2 < radius;
  };
  
  useEffect(() => {
    const detectFaces = async (webcamRef) => {
      // if(webcamRef.current.video === null) return;
      // console.log("curr video height ----> ", webcamRef.current.video.videoHeight)
      if (webcamRef.current !== null) {
        const video = webcamRef.current.video;
        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;
    
        while (isRecording && video && webcamRef.current) {
          const faces = await model.estimateFaces(video, { flipHorizontal: false });
          const validFaces = faces.filter((face) =>
            isFaceInCircularFrame(face, videoWidth, videoHeight)
          );
    
          // console.log("Detected valid faces:", validFaces);
    
          if (!validFaces.length) {
            setIsRecording(false);
            alert("Face not detected!");
            break;
          }
    
          await new Promise((resolve) => setTimeout(resolve, 100));
        }
      } else return;
    };
  
    const activeWebcamRef = () => {
      if (isDesktop) return webcamRef;
      if (isTablet) return webcamRefTab;
      if (isMobile) return webcamRefMob;
      return null;
    };
  
    const webcamRefToUse = activeWebcamRef();
  
    if (webcamRefToUse) {
      detectFaces(webcamRefToUse);
    }
  }, [isRecording, webcamRef, webcamRefTab, webcamRefMob, isDesktop, isTablet, isMobile]);
  

  const setColor = (color) => {
    document.documentElement.style.setProperty('--cbg1', color); // Updates globally
  }

  useEffect(() => {
    console.log('useEffect hook')
    if (currGen?.currGender && currGen.currGender!=='Male') {
      console.log('currGen ---> F')
      setColor('#F8E6EA')
    } else {
      console.log('currGen ---> M')
      setColor('#E8F4F6')
    }
  }, [currGen])

  const handleGenderChange = (e) => {
    const gen = e.target.value
    setGender(gen)
    console.log('gen', gen)
    setCurrGen({
      currGender: gen
    })
  }

  const handleStartRecording = async () => {
    if (!name || !age || !gender) {
      alert("Please fill in all fields: Name, Age, and Gender are required.");
      return; // Prevent recording if fields are empty
    }

    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    if (stream) {
      setIsRecording(true);
      setTimer(10);
      videoRef.current?.scrollIntoView({ behavior: 'smooth' })
    } else {
      alert("Please provide camera permission");
    }
  };

  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            captureScreenshot(); // Capture screenshot when timer ends
            setIsRecording(false); // Stop recording after 10 seconds
            return 0; // Reset timer
          }
          return prev - 1; // Decrement timer
        });
      }, 1000); // Decrement every second
    }
    return () => clearInterval(interval); // Cleanup on unmount
  }, [isRecording]);

  const captureScreenshot = () => {
    const imageSrcPc = webcamRef.current.getScreenshot();
    const imageSrcTab = webcamRefTab.current.getScreenshot();
    const imageSrcMob = webcamRefMob.current.getScreenshot();
    setIsRecording(false);

    const imageSrc = [imageSrcPc, imageSrcTab, imageSrcMob].reduce((longest, current) =>
      (current && current.length > (longest?.length || 0)) ? current : longest
    );
    
    setScreenshot(imageSrc);
    console.log("ss captured---->", imageSrc.length);
    // postData(); // Call postData immediately after capturing the screenshot

    if (imageSrc) {
      console.log("posting- called");
      console.log(imageSrc.length);
      postData(imageSrc);
    }
  };

  const postData = async (image) => {
    setLoader(true)
    console.log("posting...");
    // console.log(image.length)
    if (image != null) {
      console.log("not null");
      console.log(image.length);
      console.log(typeof image);
      console.log('gender:  ', gender)
      try {
        const apiResponse = await axios.post(
          " https://dermo.iterve.ai/predict",
          {
            image: image,
            gender: gender,
          }
        );
        console.log("Response:", apiResponse.data);
        // Redirect to results page if necessary
        navigate("/result", {
          state: {
            results: apiResponse.data,
            name: name,
            age: age,
            gender: gender,
            pic: image,
          },
        });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const navigatePath = (path) => {
    navigate(path)
  }

  useEffect(() => {
    console.log("timer:", timer);
  }, [timer]);

  return (
    <div className="h-fit w-full relative">
      {loader===true ? (
        <div className="absolute h-full w-full bg-white bg-opacity-35 z-[3] select-none">
          <div className={`${classes.loader} opacity-100`}></div>
        </div>
      ) : null}

      {/* D E S K T O P */}
      <div className="h-screen w-full flex xxs:hidden tab:flex flex-col justify-start items-center" id="desktop-container">
        <div className="h-28 w-full px-10 pt-5 flex flex-row justify-between">
          <img src={logo} alt="logo" className="h-12" />
          <div className="flex flex-row h-12 items-center select-none text-lg">
            <div className="px-10 font-medium cursor-pointer" onClick={() => navigatePath('/home')}>Home</div>
            <div className="px-10 font-medium cursor-pointer" onClick={() => navigatePath('/test')}>Take Test</div>
            <div className="px-10 font-medium cursor-pointer" onClick={() => navigatePath('/contact')}>Contact Us</div>
          </div>
          <img src={profile} alt="profile" className="h-8 mx-10 cursor-pointer" onClick={() => navigatePath('/profile')}/>
        </div>
        <div className="min-h-hit w-[80%] py-5 px-10 flex flex-row justify-between bg-cbg1 rounded-xl shadow-cs1">
          <div className="h-full w-[38%] flex flex-col justify-start">
            <form
              className="mt-24"
              onSubmit={(e) => {
                e.preventDefault();
                handleStartRecording();
              }}
            >
              <div className="w-full flex flex-col">
                <label className="text-lg">Name</label>
                <input
                  id="name"
                  type="text"
                  required // Marking field as required
                  autoComplete="name"
                  placeholder="Enter your name"
                  className="w-full h-14 mt-2 flex bg-white bg-opacity-80 rounded-lg px-4 shadow-cs1 placeholder:text-gray-700"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="w-full flex flex-col mt-4">
                <label className="text-lg">Age</label>
                <input
                  id="age"
                  type="number"
                  required // Marking field as required
                  autoComplete="age"
                  placeholder="Enter your Age"
                  className="w-full h-14 mt-2 flex bg-white bg-opacity-80 rounded-lg px-4 shadow-cs1 placeholder:text-gray-700"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                />
              </div>
              <div className="w-full flex flex-col mt-4">
                <label className="text-lg">Gender</label>
                <select
                  style={{
                    WebkitAppearance: "none",
                  }}
                  id="gender"
                  required // Marking field as required
                  className="w-full h-14 mt-2 flex bg-white bg-opacity-80 rounded-lg px-4 shadow-cs1 placeholder:text-gray-700"
                  value={gender}
                  // onChange={(e) => setGender(e.target.value)}
                  onChange={handleGenderChange}
                >
                  <option className="!text-gray-700" value="" disabled>
                    Select gender
                  </option>
                  <option className="text-black" value="Male">
                    Male
                  </option>
                  <option className="text-black" value="Female">
                    Female
                  </option>
                </select>
              </div>
              <button
                type="submit" // Changed to submit type to trigger form validation
                className="w-full h-14 mt-6 bg-cbg2 text-white font-medium rounded-lg hover:scale-[98%] focus:scale-1 transition-all duration-300 ease-in-out"
              >
                Take Video
              </button>
            </form>
          </div>
          <div className="h-full w-[58%] pl-10 flex flex-col justify-start items-center">
            <div className="text-2xl font-semibold">Capture 10 Sec Video</div>
            <div className="mt-5 text-base text-center tracking-tight">
              <b>NOTE:</b> Before recording, ensure good lighting on your face
              and avoid backlight. Keep your face fully visible, and stay still
              while looking at the camera for 10 seconds. Do not speak.
            </div>
            <div className="h-[22rem] w-[22rem] mt-10 rounded-full bg-cbg5 flex justify-center items-center relative overflow-hidden">
              {isRecording && (
                <svg className="absolute h-full w-full" viewBox="0 0 100 100">
                  <circle
                    cx="50"
                    cy="50"
                    r="50"
                    stroke="lightgray"
                    strokeWidth="5"
                    fill="transparent"
                  />
                  <circle
                    cx="50"
                    cy="50"
                    r="50"
                    stroke="#221E42"
                    strokeWidth="5"
                    fill="transparent"
                    strokeDasharray="314" // Circumference of the circle (2 * Math.PI * r)
                    strokeDashoffset={((timer - 1) / 9) * 314} // Update to decrease from full circle to 0
                    style={{
                      transition: "stroke-dashoffset 1s linear",
                      transform: "rotate(-90deg)",
                      transformOrigin: "50% 50%",
                    }}
                  />
                </svg>
              )}
              {screenshot===null ? (
                <Webcam
                  audio={false}
                  mirrored={true}
                  ref={webcamRef}
                  videoConstraints={{
                    width: 480,
                    height: 480,
                    facingMode: "user",
                  }}
                  className="absolute h-full w-full object-cover rounded-full p-3"
                  screenshotFormat="image/jpeg"
                />
              ) : screenshot ? (
                <img
                  src={screenshot}
                  alt="screenshot"
                  className="h-full w-full rounded-full p-3"
                />
              ) : (
                <img src={camera} alt="camera" className="h-40" />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* T A B */}
      <div className="h-screen w-full flex xxs:hidden tabPotrait:flex tab:hidden flex-col justify-start items-center bg-white" id="tab-container">
        <div className="h-28 w-full px-10 pt-5 flex flex-row justify-between">
          <img src={logo} alt="logo" className="h-12 select-none"/>
          <div className="flex flex-row h-12 items-center select-none">
            <div className="px-5 font-medium cursor-pointer" onClick={() => navigatePath('/home')}>Home</div>
            <div className="px-5 font-medium cursor-pointer" onClick={() => navigatePath('/test')}>Test</div>
            <div className="px-5 font-medium cursor-pointer" onClick={() => navigatePath('/contact')}>Contact Us</div>
          </div>
          <img src={profile} alt="profile" className="h-8 mx-10 cursor-pointer" onClick={() => navigatePath('/profile')}/>
        </div>
        <div className="min-h-hit w-[90%] py-5 px-10 flex flex-row justify-between bg-cbg1 rounded-xl shadow-cs1">
          <div className="h-full w-[42%] flex flex-col justify-start">
            <form
              className="mt-28"
              onSubmit={(e) => {
                e.preventDefault();
                handleStartRecording();
              }}
            >
              <div className="w-full flex flex-col">
                <label className="text-lg">Name</label>
                <input
                  id="nameTab"
                  type="text"
                  required // Marking field as required
                  autoComplete="name"
                  placeholder="Enter your name"
                  className="w-full h-14 mt-2 flex bg-white bg-opacity-80 rounded-lg px-4 shadow-cs1 placeholder:text-gray-700"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="w-full flex flex-col mt-4">
                <label className="text-lg">Age</label>
                <input
                  id="ageTab"
                  type="number"
                  required // Marking field as required
                  autoComplete="age"
                  placeholder="Enter your Age"
                  className="w-full h-14 mt-2 flex bg-white bg-opacity-80 rounded-lg px-4 shadow-cs1 placeholder:text-gray-700"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                />
              </div>
              <div className="w-full flex flex-col mt-4">
                <label className="text-lg">Gender</label>
                <select
                  style={{
                    WebkitAppearance: "none",
                  }}
                  id="genderTab"
                  required // Marking field as required
                  className="w-full h-14 mt-2 flex bg-white bg-opacity-80 rounded-lg px-4 shadow-cs1 placeholder:text-gray-700"
                  value={gender}
                  // onChange={(e) => setGender(e.target.value)}
                  onChange={handleGenderChange}
                >
                  <option className="!text-gray-700" value="" disabled>
                    Select gender
                  </option>
                  <option className="text-black" value="Male">
                    Male
                  </option>
                  <option className="text-black" value="Female">
                    Female
                  </option>
                </select>
              </div>
              <button
                type="submit" // Changed to submit type to trigger form validation
                className="w-full h-14 mt-6 bg-cbg2 text-white font-medium rounded-lg hover:scale-[98%] focus:scale-1 transition-all duration-300 ease-in-out"
              >
                Take Video
              </button>
            </form>
          </div>
          <div className="h-full w-[55%] pl-10 flex flex-col justify-start items-center">
            <div className="text-2xl font-semibold">Capture 10 Sec Video</div>
            <div className="mt-4 text-base text-center leading-tight tracking-tight">
              <b>NOTE:</b> Before recording, ensure good lighting on your face
              and avoid backlight. Keep your face fully visible, and stay still
              while looking at the camera for 10 seconds. Do not speak.
            </div>
            <div className="h-[23rem] w-[23rem] mt-5 rounded-full bg-cbg5 flex justify-center items-center relative overflow-hidden">
              {isRecording && (
                <svg className="absolute h-full w-full" viewBox="0 0 100 100">
                  <circle
                    cx="50"
                    cy="50"
                    r="50"
                    stroke="lightgray"
                    strokeWidth="5"
                    fill="transparent"
                  />
                  <circle
                    cx="50"
                    cy="50"
                    r="50"
                    stroke="#221E42"
                    strokeWidth="5"
                    fill="transparent"
                    strokeDasharray="314" // Circumference of the circle (2 * Math.PI * r)
                    strokeDashoffset={((timer - 1) / 9) * 314} // Update to decrease from full circle to 0
                    style={{
                      transition: "stroke-dashoffset 1s linear",
                      transform: "rotate(-90deg)",
                      transformOrigin: "50% 50%",
                    }}
                  />
                </svg>
              )}
              {screenshot===null ? (
                <Webcam
                  audio={false}
                  mirrored={true}
                  ref={webcamRefTab} // Assign the webcamRef to the Webcam component
                  videoConstraints={{
                    width: 480,
                    height: 480,
                    facingMode: "user",
                  }}
                  className="absolute h-full w-full object-cover rounded-full p-3"
                  screenshotFormat="image/jpeg" // Specify the screenshot format
                />
              ) : screenshot ? (
                <img
                  src={screenshot}
                  alt="screenshot"
                  className="h-full w-full rounded-full p-3"
                /> // Display screenshot after recording
              ) : (
                <img src={camera} alt="camera" className="h-40" />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* M O B I L E */}
      <div className="h-screen min-h-fit w-full flex tabPotrait:hidden flex-col justify-start items-center bg-white">
        <div className="h-20 w-full px-3 py-3 md:py-2 flex flex-row justify-between items-center">
          <img src={logo} alt="logo" className="h-10 select-none" />
          <div className="h-full gap-5 flex flex-row items-center select-none md:text-lg">
            <div className="md:w-24 font-semibold cursor-pointer select-none" onClick={() => navigatePath('/home')}>Home</div>
            <div className="md:w-24 font-semibold cursor-pointer select-none" onClick={() => navigatePath('/test')}>Test</div>
            <div className="md:w-24 font-semibold cursor-pointer select-none" onClick={() => navigatePath('/contact')}>Contact Us</div>
          </div>
          <img src={profile} alt="profile" className="h-8 px-6 cursor-pointer" onClick={() => navigatePath('/profile')}/>
        </div>
        <div className="min-h-hit w-full py-5 px-10 flex flex-col justify-between bg-cbg1">
          <div className="h-full w-full flex flex-col justify-start">
            <form
              className="mt-5"
              onSubmit={(e) => {
                e.preventDefault();
                handleStartRecording();
              }}
            >
              <div className="w-full flex flex-col">
                <label className="text-lg">Name</label>
                <input
                  id="nameMob"
                  type="text"
                  required // Marking field as required
                  autoComplete="name"
                  placeholder="Enter your name"
                  className="w-full h-14 mt-2 flex bg-white bg-opacity-80 rounded-lg px-4 shadow-cs1 placeholder:text-gray-700"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="w-full flex flex-col mt-4">
                <label className="text-lg">Age</label>
                <input
                  id="ageMob"
                  type="number"
                  required // Marking field as required
                  autoComplete="age"
                  placeholder="Enter your Age"
                  className="w-full h-14 mt-2 flex bg-white bg-opacity-80 rounded-lg px-4 shadow-cs1 placeholder:text-gray-700"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                />
              </div>
              <div className="w-full flex flex-col mt-4">
                <label className="text-lg">Gender</label>
                <select
                  style={{
                    // boxShadow: '0px 0px 0px 0px #0000, 0px 0px 0px 0px #0000, 2px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    WebkitAppearance: "none",
                  }}
                  id="genderMob"
                  required // Marking field as required
                  className="w-full h-14 mt-2 flex bg-white bg-opacity-80 rounded-lg px-4 shadow-cs1 placeholder:text-gray-700"
                  value={gender}
                  // onChange={(e) => setGender(e.target.value)}
                  onChange={handleGenderChange}
                >
                  <option className="!text-gray-700" value="" disabled>
                    Select gender
                  </option>
                  <option className="text-black" value="Male">
                    Male
                  </option>
                  <option className="text-black" value="Female">
                    Female
                  </option>
                </select>
              </div>
              <div className="w-full h-fit flex flex-row justify-center">
                <button
                  type="submit" // Changed to submit type to trigger form validation
                  className="w-[40%] h-14 mt-6 bg-cbg2 text-white font-medium rounded-xl focus:scale-[95%] hover:scale-[95%] transition-all duration-300 ease-in-out"
                >
                  Take Video
                </button>
              </div>
            </form>
          </div>
          <div className="h-full w-full py-10 px-8 flex flex-col justify-start items-center" ref={videoRef}>
            <div className="text-2xl font-semibold">Capture 10 Sec Video</div>
            <div className="mt-4 text-base text-center leading-tight tracking-tight">
              <b>NOTE:</b> Before recording, ensure good lighting on your face
              and avoid backlight. Keep your face fully visible, and stay still
              while looking at the camera for 10 seconds. Do not speak.
            </div>
            <div className="h-[20rem] w-[20rem] mt-5 rounded-full bg-cbg5 flex justify-center items-center relative overflow-hidden">
              {isRecording && (
                <svg className="absolute h-full w-full" viewBox="0 0 100 100">
                  <circle
                    cx="50"
                    cy="50"
                    r="50"
                    stroke="lightgray"
                    strokeWidth="5"
                    fill="transparent"
                  />
                  <circle
                    cx="50"
                    cy="50"
                    r="50"
                    stroke="#221E42"
                    strokeWidth="5"
                    fill="transparent"
                    strokeDasharray="314" // Circumference of the circle (2 * Math.PI * r)
                    strokeDashoffset={((timer - 1) / 9) * 314} // Update to decrease from full circle to 0
                    style={{
                      transition: "stroke-dashoffset 1s linear",
                      transform: "rotate(-90deg)",
                      transformOrigin: "50% 50%",
                    }}
                  />
                </svg>
              )}
              {screenshot===null ? (
                <Webcam
                  audio={false}
                  mirrored={true}
                  ref={webcamRefMob} // Assign the webcamRef to the Webcam component
                  videoConstraints={{
                    width: 480,
                    height: 480,
                    facingMode: "user",
                  }}
                  className="absolute h-full w-full object-cover rounded-full p-3"
                  screenshotFormat="image/jpeg" // Specify the screenshot format
                />
              ) : screenshot ? (
                <img
                  src={screenshot}
                  alt="screenshot"
                  className="h-full w-full rounded-full p-3"
                /> // Display screenshot after recording
              ) : (
                <img src={camera} alt="camera" className="h-40" />
              )}
            </div>
          </div>
        </div>
        <div className="w-full h-fit px-10 py-10 mt-10 bg-cbg6">
          <img src={logo} alt="logo" className="h-12" />
          <div className="w-full mt-5 text-gray-400">
            Dermo Iterve is an advanced AI-powered skin analysis platform that
            provides instant insights into your skin's health. With a simple
            10-second face video, you can detect skin conditions, monitor for
            early signs of skin cancer, and receive personalized skincare
            recommendations.
          </div>
          <div className="w-full h-fit mt-10 flex justify-center text-white">
            <div className="w-full flex flex-row justify-between text-xl select-none">
              <div onClick={() => navigatePath('/home')}>Home</div>
              <div onClick={() => navigatePath('/test')}>Test</div>
              <div onClick={() => navigatePath('/contact')}>Contact Us</div>
            </div>
          </div>
          <div className="mt-10 text-center text-white">
            Copyright © 2024 AIVOT - All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelfTest;
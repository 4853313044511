import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./home.module.css";
import acne from "../images/acne.svg";
import clear from "../images/clear.svg";
import melasma from "../images/melasma.svg";
import milia from "../images/milia.svg";
import rocasea from "../images/rocasea.svg";
import vitiligo from "../images/vitiligo.svg";
import phone from "../images/phone_with_image.png";
import cancer1 from "../images/cancer1.svg";
import cancer2 from "../images/cancer2.svg";
import cancer3 from "../images/cancer3.svg";
import cancer4 from "../images/cancer4.svg";
import cancer5 from "../images/cancer5.svg";
import cancer6 from "../images/cancer6.svg";
import cancer7 from "../images/cancer7.svg";
import cancer8 from "../images/cancer8.svg";
import ribbon from "../images/ribbon.png";
import side_face from "../images/side_face.svg";
import face_M from "../images/facemask.png";
import mask from "../images/mask.svg";
import glass from "../images/MagnifyingGlass.svg";
import search from "../images/glass.png";
import insta from "../images/Instagram.png";
import linkedin from "../images/LinkedIn.png";
import fb from "../images/Facebook.png";
import aivot from "../images/aivot.png";
import pic1 from "../images/pic1.png";
import vector from "../images/vector.svg";
import darkcircle from "../images/skintype1.svg";
import skintype from "../images/skintype.png";
import eye1 from "../images/eye1.svg";
import eye2 from "../images/eye2.png";
import V from "../images/V.png";
import H from "../images/H_1.png";
import Wrinkle1 from "../images/Wrinkle1.svg";
import Wrinkle2 from "../images/Wrinkle2.png";
import Wrinkle3 from "../images/Wrinkle3.svg";
import Wrinkle4 from "../images/Wrinkle4.png";
import label_line from "../images/label_line.svg";
import line1 from "../images/line1.png";
import line2 from "../images/line2.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rightarrow from "../images/rightarrow.png";
import profile from "../images/profile.svg";
import male from "../images/male.png";
import pic2 from "../images/pic3.png";
import malephone from "../images/malephone.png";
import { Link } from "react-router-dom";
import Profile from "../images/profile.png";
import { UserContext } from "../context/context";

const Home = () => {
  const navigate = useNavigate();
  const [gender, setGender] = useState(""); // State to hold the selected gender
  const { currGen, setCurrGen } = useContext(UserContext);
  const [clicked, setClicked] = useState(false); // Track if a progress bar was clicked

  const [showSkinDisease, setShowSkinDisease] = useState(true);
  const [contentIndex, setContentIndex] = useState(0);

  const [activeBar, setActiveBar] = useState(0);
  const [progress, setProgress] = useState(0);
  const [showTransitionOverlay, setShowTransitionOverlay] = useState(false);
  
  const handleTestNavigation = () => {
    navigate("/test");
  };

  // console.log(currGen.currGender);
  useEffect(() => {
    if (currGen.currGender) {
      console.log("gender set")
      setGender(currGen.currGender); // This updates the gender state
    }
  }, [currGen.currGender]);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2, // Display 2 slides at a time (4 items)
    slidesToScroll: 2,
  };
  const secondSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const Card = ({ icon, title, description }) => (
    <div
      className={styles.card}
      style={{ backgroundColor: gender === "Male" ? "#E8F4F6" : "#f8e6ea" }}
    >
      <img src={icon} alt={title} className={styles.icon} />
      <h3 className={styles.cardTitle}>{title}</h3>
      <p className={styles.cardDescription}>{description}</p>
    </div>
  );

  // Reusable Feature Card with Button
  const FeatureCard = ({ func, icon, title, description, useGen }) => {
    return (
      <div
        className={styles.featureCard}
        style={{ backgroundColor: useGen === "Male" ? "#E8F4F6" : "#f8e6ea" }}
      >
        <img src={icon} alt={title} className={styles.icon} />
        <h3 className={styles.featureTitle}>{title}</h3>
        <p className={styles.featureDescription}>{description}</p>
        {func==="dis" ? (
          <button className={styles.button3} onClick={() => {navigate('/test')}}>
            TAKE THE TEST NOW
          </button>
        ) : (
          <button className={styles.button3} onClick={() => {navigate('/skincancer')}}>
            TAKE THE TEST NOW
          </button>
        )}
      </div>
    )
  };
  const desksettings = {
    dots: true, // Show navigation dots
    infinite: true, // Infinite looping
    speed: 500, // Animation speed
    slidesToShow: 1, // Number of slides to show
    slidesToScroll: 1, // Number of slides to scroll
    autoplay: true, // Enable autoplay
    autoplaySpeed: 10000, // Time between slides
  };
  const contentArray = [
    <div className={styles.vectorsec}>
      <div
        className={styles.imageWrapper}
        onMouseEnter={() => handleHover(["face", "line1"])}
        onMouseLeave={() => handleLeave(["face", "line1"])}
      >
        <img src={vector} alt="vector" className={styles.vector} />
        <span className={styles.label}>SKIN TONE</span>
      </div>

      {/* EYE SHAPE */}
      <div
        className={styles.imageWrapper}
        onMouseEnter={() => handleHover(["eye2", "eye1", "line2"])}
        onMouseLeave={() => handleLeave(["eye2", "eye1", "line2"])}
      >
        <img src={vector} alt="vector" className={styles.vector} />
        <span className={styles.label}>EYE SHAPE</span>
      </div>

      {/* SKIN TYPE */}
      <div
        className={styles.imageWrapper}
        onMouseEnter={() => handleHover(["skintype", "skintype_line"])}
        onMouseLeave={() => handleLeave(["skintype", "skintype_line"])}
      >
        <img src={vector} alt="vector" className={styles.vector} />
        <span className={styles.label}>SKIN TYPE</span>
      </div>

      {/* DARK CIRCLES */}
      <div
        className={styles.imageWrapper}
        onMouseEnter={() =>
          handleHover(["darkcircle1", "darkcircle2", "darkcircle_line"])
        }
        onMouseLeave={() =>
          handleLeave(["darkcircle1", "darkcircle2", "darkcircle_line"])
        }
      >
        <img src={vector} alt="vector" className={styles.vector} />
        <span className={styles.label}>DARK CIRCLES</span>
      </div>

      {/* WRINKLES */}
      <div
        className={styles.imageWrapper}
        onMouseEnter={() =>
          handleHover([
            "Wrinkle1",
            "Wrinkle2",
            "Wrinkle3",
            "Wrinkle4",
            "wrinkle_line",
          ])
        }
        onMouseLeave={() =>
          handleLeave([
            "Wrinkle1",
            "Wrinkle2",
            "Wrinkle3",
            "Wrinkle4",
            "wrinkle_line",
          ])
        }
      >
        <img src={vector} alt="vector" className={styles.vector} />
        <span className={styles.label}>WRINKLES</span>
      </div>

      <div
        className={styles.imageWrapper}
        onMouseEnter={() =>
          handleHover([
            "firstH",
            "secondH",
            "thirdH",
            "vertical",
            "faceshape_line",
          ])
        }
        onMouseLeave={() =>
          handleLeave([
            "firstH",
            "secondH",
            "thirdH",
            "vertical",
            "faceshape_line",
          ])
        }
      >
        <img src={vector} alt="vector" className={styles.vector} />
        <span className={styles.label}>FACE SHAPE</span>
      </div>
    </div>,

    <div className={styles.vectorsec}>
      <div
        className={styles.imageWrapper}
        onMouseEnter={() => handleHover(["fvacne", "line1"])}
        onMouseLeave={() => handleLeave(["fvacne", "line1"])}
      >
        <img src={vector} alt="vector" className={styles.vector} />
        <span className={styles.label}>ACNE</span>
      </div>

      <div
        className={styles.imageWrapper}
        onMouseEnter={() => handleHover(["fvmelasma", "line2"])}
        onMouseLeave={() => handleLeave(["fvmelasma", "line2"])}
      >
        <img src={vector} alt="vector" className={styles.vector} />
        <span className={styles.label}>MELASMA</span>
      </div>

      {/* SKIN TYPE */}
      <div
        className={styles.imageWrapper}
        onMouseEnter={() => handleHover(["fvrocasea", "skintype_line"])}
        onMouseLeave={() => handleLeave(["fvrocasea", "skintype_line"])}
      >
        <img src={vector} alt="vector" className={styles.vector} />
        <span className={styles.label}>ROCASEA</span>
      </div>

      {/* DARK CIRCLES */}
      <div
        className={styles.imageWrapper}
        onMouseEnter={() => handleHover(["fvmilia", "darkcircle_line"])}
        onMouseLeave={() => handleLeave(["fvmila", "darkcircle_line"])}
      >
        <img src={vector} alt="vector" className={styles.vector} />
        <span className={styles.label}>MILIA</span>
      </div>

      {/* WRINKLES */}
      <div
        className={styles.imageWrapper}
        onMouseEnter={() => handleHover(["fvvitiligo", "wrinkle_line"])}
        onMouseLeave={() => handleLeave(["fvvitiligo", "wrinkle_line"])}
      >
        <img src={vector} alt="vector" className={styles.vector} />
        <span className={styles.label}>VITILIGO</span>
      </div>

      {/* FACE SHAPE */}
      <div
        className={styles.imageWrapper}
        onMouseEnter={() => handleHover(["fvclear", "faceshape_line"])}
        onMouseLeave={() => handleLeave(["fvclear", "faceshape_line"])}
      >
        <img src={vector} alt="vector" className={styles.vector} />
        <span className={styles.label}>CLEAR SKIN</span>
      </div>
    </div>,

    <div>
      <div className={styles.vectorsec}>
        <div
          className={styles.imageWrapper}
          onMouseEnter={() => handleHover(["cancer1", "line1"])}
          onMouseLeave={() => handleLeave(["cancer1", "line1"])}
        >
          <img src={vector} alt="vector" className={styles.vector} />
          <span className={styles.label}>ACTINIC KERATOSIS</span>
        </div>

        <div
          className={styles.imageWrapper}
          onMouseEnter={() => handleHover(["cancer2", "line2"])}
          onMouseLeave={() => handleLeave(["cancer2", "line2"])}
        >
          <img src={vector} alt="vector" className={styles.vector} />
          <span className={styles.label}>BASAL CELL CARCINOMA</span>
        </div>

        {/* SKIN TYPE */}
        <div
          className={styles.imageWrapper}
          onMouseEnter={() => handleHover(["cancer3", "skintype_line"])}
          onMouseLeave={() => handleLeave(["cancer3", "skintype_line"])}
        >
          <img src={vector} alt="vector" className={styles.vector} />
          <span className={styles.label}>MELANOMA</span>
        </div>

        {/* DARK CIRCLES */}
        <div
          className={styles.imageWrapper}
          onMouseEnter={() => handleHover(["cancer5", "darkcircle_line"])}
          onMouseLeave={() => handleLeave(["cancer5", "darkcircle_line"])}
        >
          <img src={vector} alt="vector" className={styles.vector} />
          <span className={styles.label}>NEVUS</span>
        </div>

        {/* WRINKLES */}
        <div
          className={styles.imageWrapper}
          onMouseEnter={() => handleHover(["cancer6", "wrinkle_line"])}
          onMouseLeave={() => handleLeave(["cancer6", "wrinkle_line"])}
        >
          <img src={vector} alt="vector" className={styles.vector} />
          <span className={styles.label}>SEBORRHEIC KERATOSIS</span>
        </div>

        {/* FACE SHAPE */}
        <div
          className={styles.imageWrapper}
          onMouseEnter={() => handleHover(["cancer7", "faceshape_line"])}
          onMouseLeave={() => handleLeave(["cancer7", "faceshape_line"])}
        >
          <img src={vector} alt="vector" className={styles.vector} />
          <span className={styles.label}>DERMATOFIBROMA</span>
        </div>
      </div>
      <div className={styles.vectorsec2}>
        {/* SKIN TYPE */}
        <div
          className={styles.imageWrapper2}
          onMouseEnter={() => handleHover(["cancer4", "rightarrow1"])}
          onMouseLeave={() => handleLeave(["cancer4", "rightarrow1"])}
        >
          <img src={vector} alt="vector" className={styles.vector} />
          <span className={styles.labelc}>PIGMENTED BENIGN KERATOSIS</span>
        </div>

        {/* DARK CIRCLES */}
        <div
          className={styles.imageWrapper2}
          onMouseEnter={() => handleHover(["cancer8", "rightarrow2"])}
          onMouseLeave={() => handleLeave(["cancer8", "rightarrow2"])}
        >
          <img src={vector} alt="vector" className={styles.vector} />
          <span className={styles.labelc}>VASCULAR LESION</span>
        </div>
      </div>

      {/* WRINKLES */}
    </div>,
  ];
  const head1Array = [
    "Comprehensive Skin Analysis Powered by AI",
    "Advanced Skin Disease Detection Powered by AI",
    "Skin Cancer Detection Powered by AI",
  ];

  const head2Array = [
    "Analyze your skin type, tone, and condition with AI technology that detects wrinkles, dark circles, and more in real-time. By simply taking a 10-second video, identify potential skin diseases, including cancer, and receive personalized skincare insights and recommendations for better skin health.",
    "Get precise insights into skin conditions such as acne, melasma, Rocase, Milia, Vitiligo and clear skin with cutting-edge AI analysis. In just a 10-second video, detect potential issues and receive tailored recommendations for treatment and skincare.",
    "Get precise insights into potential skin cancer risks, including melanoma, basal cell carcinoma, and squamous cell carcinoma, with cutting-edge AI analysis. In just a 10-second video, detect early signs of cancer and receive personalized recommendations for further testing and care, helping you take control of your skin health.",
  ];

  useEffect(() => {
    if (clicked) return;
    // Change content index every 5 seconds
    const timer = setInterval(() => {
      setContentIndex((prevIndex) => (prevIndex + 1) % contentArray.length); // Loop through content array
    }, 10000);

    return () => clearInterval(timer); // Cleanup timer on unmount
  }, [clicked]);
  const conditions = [
    {
      title: "ACNE",
      description:
        "It is a common skin condition that occurs when hair follicles become clogged with oil, dead skin cells, and bacteria. It manifests as pimples, blackheads, and cysts, typically appearing on the face, back, and shoulders.",
      imageUrl: acne,
    },
    {
      title: "ROCASEA",
      description:
        "It is a chronic skin condition that causes redness, visible blood vessels, and bumps on the face. It often affects the cheeks, nose, and forehead.",
      imageUrl: rocasea,
    },
    {
      title: "MILIA",
      description:
        "They are small, white cysts that typically form around the eyes, cheeks, and nose. They occur when keratin becomes trapped beneath the surface.",
      imageUrl: milia,
    },
    {
      title: "VITILIGO",
      description:
        "It is a skin disorder that causes loss of skin color in patches. It occurs when melanocytes are destroyed, leading to areas of lighter skin.",
      imageUrl: vitiligo,
    },
    {
      title: "CLEAR SKIN",
      description:
        "Clear skin is often considered healthy skin without visible blemishes, discoloration, or any conditions. It typically has a smooth texture and even tone.",
      imageUrl: clear,
    },
    {
      title: "MELASMA",
      description:
        "It is characterized by brown or gray-brown patches on the skin, often appearing on the face, particularly on the cheeks, forehead, and upper lip.",
      imageUrl: melasma,
    },
  ];
  const cancer = [
    {
      title: "ACTINIC KERATOSIS",
      description:
        "A rough, scaly patch on the skin caused by prolonged exposure to the sun, often appearing on the face, scalp, or hands. It can be a precursor to skin cancer if left untreated.",
      imageUrl: cancer1,
    },
    {
      title: "BASAL CELL CARCINOMA",
      description:
        "The most common form of skin cancer, often caused by sun exposure. It appears as a pearly or waxy bump on sun-exposed areas and rarely spreads.",
      imageUrl: cancer2,
    },
    {
      title: "MELANOMA",
      description:
        "A dangerous form of skin cancer that develops in melanocytes, often appearing as an irregular mole or dark spot. Early detection is critical.",
      imageUrl: cancer3, // Example, change to correct image
    },
    {
      title: "PIGMENTED BENIGN KERATOSIS",
      description:
        "A type of benign growth that appears as a dark, slightly raised patch on the skin, often mistaken for melanoma. It's common in older adults and generally harmless.",
      imageUrl: cancer4, // Example, change to correct image
    },
    {
      title: "NEVUS",
      description:
        "Commonly known as a mole, a nevus is a benign growth of melanocytes. While most are harmless, some can develop into melanoma.",
      imageUrl: cancer5, // Example, change to correct image
    },
    {
      title: "SEBORRHEIC KERATOSIS",
      description:
        "A benign, noncancerous skin growth that looks waxy, wart-like, or scaly. Often appearing in older adults, seborrheic keratoses are harmless.",
      imageUrl: cancer6, // Example, change to correct image
    },
    {
      title: "DERMATOFIBROMA",
      description:
        "A small, firm bump under the skin, often appearing on the legs or arms. Dermatofibromas are harmless and usually don't require treatment.",
      imageUrl: cancer7, // Example, change to correct image
    },
    {
      title: "VASCULAR LESION",
      description:
        "An abnormal growth of blood vessels that appears as red or purple spots on the skin. Most vascular lesions are benign, but some may require treatment for cosmetic or medical reasons.",
      imageUrl: cancer8, // Example, change to correct image
    },
  ];

  const hideAllElements = () => {
    const elementsToHide = [
      "face",
      "darkcircle1",
      "darkcircle2",
      "skintype",
      "eye1",
      "eye2",
      "firstH",
      "secondH",
      "thirdH",
      "vertical",
      "Wrinkle1",
      "Wrinkle2",
      "Wrinkle3",
      "Wrinkle4",
      "fvacne",
      "fvmelasma",
      "fvrocasea",
      "fvmilia",
      "fvvitiligo",
      "fvclear",
      "line1",
      "line2",
      "skintype_line",
      "darkcircle_line",
      "faceshape_line",
      "wrinkle_line",
      "rightarrow1",
      "rightarrow2",
      "cancer1",
      "cancer2",
      "cancer3",
      "cancer4",
      "cancer5",
      "cancer6",
      "cancer7",
      "cancer8",
    ];

    elementsToHide.forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        element.style.display = "none";
      }
    });
  };

  const handleHover = (ids) => {
    // Hide all elements before showing the new ones
    hideAllElements();

    ids.forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        element.style.display = "block";
      }
    });
  };

  const handleLeave = (ids) => {
    ids.forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        element.style.display = "none";
      }
    });
  };
  const navigatePath = (path) => {
    navigate(path);
  };
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  const handlepClick = (index) => {
    setActiveBar(index); // Set active bar to the clicked one
    setProgress(0);
    setContentIndex(index); 
    setClicked(true); // Mark as clicked, disabling auto content change
    setTimeout(() => setClicked(false), 0);// Set contentIndex to the index of the clicked bar
  };
  useEffect(() => {
   

    setProgress(0);

    // Gradually increase progress from 0 to 100 over 10 seconds
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(progressInterval);
          return 100;
        }
        return prevProgress + 1; // Increase by 1% every 100ms
      });
    }, 100); // 100 ms interval for smoother animation

    // Move to the next bar after 10 seconds
    const barInterval = setInterval(() => {
      setActiveBar((prevActiveBar) => (prevActiveBar + 1) % 3);
    }, 10000); // 10 seconds per bar

    return () => {
      clearInterval(progressInterval);
      clearInterval(barInterval);
    };
  }, [activeBar]);
  return (
    <div className={styles.container}>
      <div className="h-16 w-full px-3 py-3 md:py-2 flex tabPotrait:hidden flex-row justify-between items-center bg-white">
        <img src={aivot} alt="logo" className="h-10 select-none" />
        <div className="h-full gap-5 flex flex-row items-center select-none md:text-lg">
          <div
            className="md:w-24 font-semibold cursor-pointer select-none"
            onClick={() => navigatePath("/home")}
          >
            Home
          </div>
          <div
            className="md:w-24 font-semibold cursor-pointer select-none"
            onClick={() => navigatePath("/test")}
          >
            Test
          </div>
          <div
            className="md:w-24 font-semibold cursor-pointer select-none"
            onClick={() => navigatePath("/contact")}
          >
            Contact Us
          </div>
        </div>
        <img
          src={Profile}
          alt="profile"
          className="h-8 px-6 cursor-pointer"
          onClick={() => navigatePath("/profile")}
        />
      </div>
      <div className={styles.sec} id="sec">
        <div
          className={styles.sec1}
          style={{ backgroundColor: gender === "Male" ? "#E8F4F6" : "#f8e6ea" }}
        >
          <nav className={styles.navbar}>
            <div className={styles.logo}>
              <img src={aivot} alt="Aivot Logo" className={styles.logoImage} />
            </div>
            <ul className={styles.navLinksfirst}>
              <li>
                <Link to="/home">Home</Link>
              </li>
              <li>
                <Link to="/test">Take Test</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </nav>
          <div className={styles.leftsec}>
            <div className={styles.head1}>{head1Array[contentIndex]} </div>
            <div className={styles.head2}>{head2Array[contentIndex]}</div>
            <div className={styles.btn} onClick={handleTestNavigation}>
              Take the Skin Test
            </div>
            <div className={styles.progressContainer}>
              {showTransitionOverlay && (
                <div className={styles.transitionOverlay}></div>
              )}

              <div
                className={styles.progressBar}
                onClick={() => handlepClick(0)}
              >
                <div
                  className={`${styles.fill} ${
                    activeBar === 0 ? styles.blue : ""
                  }`}
                  style={{ width: activeBar === 0 ? `${progress}%` : "0%" }}
                ></div>
              </div>
              <div
                className={styles.progressBar}
                onClick={() => handlepClick(1)}
              >
                <div
                  className={`${styles.fill} ${
                    activeBar === 1 ? styles.blue : ""
                  }`}
                  style={{ width: activeBar === 1 ? `${progress}%` : "0%" }}
                ></div>
              </div>
              <div
                className={styles.progressBar}
                onClick={() => handlepClick(2)}
              >
                <div
                  className={`${styles.fill} ${
                    activeBar === 2 ? styles.blue : ""
                  }`}
                  style={{ width: activeBar === 2 ? `${progress}%` : "0%" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.sec2}>
          <div className={styles.userOptions}>
            <div className={styles.userIconContainer}>
              <img
                src={profile}
                alt="Profile Icon"
                className={styles.userIcon}
                onClick={() => navigatePath("/profile")}
              />
            </div>
          </div>
          {contentArray[contentIndex]}{" "}
          {/* Render content based on current index */}
        </div>
        <div className={styles.imagewrap}>
          <img
            src={gender === "Male" ? pic2 : pic1} // Change image based on gender
            alt="Intersection Image"
            className={
              gender === "Male"
                ? styles.maleIntersectionImage
                : styles.intersectionImage
            }
          />
          <div alt="Overlay 1" className={styles.skintone} id="face" />
          <img
            src={line1}
            alt="Overlay 1"
            className={styles.line1}
            id="line1"
          />

          <img
            src={darkcircle}
            alt="Overlay 1"
            className={styles.darkcircle1}
            id="darkcircle1"
          />
          <img
            src={label_line}
            alt="Overlay 1"
            className={styles.darkcircleline}
            id="darkcircle_line"
          />

          <img
            src={darkcircle}
            alt="Overlay 2"
            className={styles.darkcircle2}
            id="darkcircle2"
          />
          <img
            src={skintype}
            alt="Overlay 3"
            className={styles.skintype}
            id="skintype"
          />
          <img
            src={line2}
            alt="Overlay 1"
            className={styles.skintypeline}
            id="skintype_line"
          />

          <img src={eye1} alt="Overlay 4" className={styles.eye1} id="eye1" />
          <img src={eye2} alt="Overlay 5" className={styles.eye2} id="eye2" />
          <img
            src={line2}
            alt="Overlay 1"
            className={styles.line2}
            id="line2"
          />

          <img src={H} alt="Overlay 6" className={styles.firstH} id="firstH" />
          <img
            src={H}
            alt="Overlay 7"
            className={styles.secondH}
            id="secondH"
          />
          <img src={H} alt="Overlay 8" className={styles.thirdH} id="thirdH" />
          <img
            src={V}
            alt="Vertical Line"
            className={styles.vertical}
            id="vertical"
          />
          <img
            src={label_line}
            alt="Overlay 1"
            className={styles.faceshapeline}
            id="faceshape_line"
          />

          <img
            src={Wrinkle1}
            alt="Overlay Wrinkle 1"
            className={styles.Wrinkle1}
            id="Wrinkle1"
          />
          <img
            src={Wrinkle2}
            alt="Overlay Wrinkle 2"
            className={styles.Wrinkle2}
            id="Wrinkle2"
          />
          <img
            src={Wrinkle3}
            alt="Overlay Wrinkle 3"
            className={styles.Wrinkle3}
            id="Wrinkle3"
          />
          <img
            src={Wrinkle4}
            alt="Overlay Wrinkle 4"
            className={styles.Wrinkle4}
            id="Wrinkle4"
          />
          <img
            src={label_line}
            alt="Overlay 1"
            className={styles.wrinkleline}
            id="wrinkle_line"
          />

          <img
            src={acne}
            alt="Overlay acne"
            className={styles.fvacne}
            id="fvacne"
          />
          <img
            src={melasma}
            alt="Overlay melasma"
            className={styles.fvmelasma}
            id="fvmelasma"
          />
          <img
            src={milia}
            alt="Overlay melasma"
            className={styles.fvmilia}
            id="fvmilia"
          />
          <img
            src={vitiligo}
            alt="Overlay melasma"
            className={styles.fvvitiligo}
            id="fvvitiligo"
          />
          <img
            src={rocasea}
            alt="Overlay melasma"
            className={styles.fvrocasea}
            id="fvrocasea"
          />
          <img
            src={clear}
            alt="Overlay melasma"
            className={styles.fvclear}
            id="fvclear"
          />

          <img
            src={cancer1}
            alt="Overlay acne"
            className={styles.fvacne}
            id="cancer1"
          />
          <img
            src={cancer2}
            alt="Overlay melasma"
            className={styles.fvmelasma}
            id="cancer2"
          />
          <img
            src={cancer3}
            alt="Overlay melasma"
            className={styles.fvmilia}
            id="cancer3"
          />
          <img
            src={cancer4}
            alt="Overlay melasma"
            className={styles.fvvitiligo}
            id="cancer4"
          />
          <img
            src={cancer5}
            alt="Overlay melasma"
            className={styles.fvrocasea}
            id="cancer5"
          />
          <img
            src={cancer6}
            alt="Overlay melasma"
            className={styles.fvvitiligo}
            id="cancer6"
          />
          <img
            src={cancer7}
            alt="Overlay melasma"
            className={styles.fvclear}
            id="cancer7"
          />
          <img
            src={cancer8}
            alt="Overlay melasma"
            className={styles.fvclear}
            id="cancer8"
          />

          <img
            src={rightarrow}
            alt="Overlay melasma"
            className={styles.rightarrow1}
            id="rightarrow1"
          />
          <img
            src={rightarrow}
            alt="Overlay melasma"
            className={styles.rightarrow2}
            id="rightarrow2"
          />
        </div>
      </div>
      <div className={styles.middle}>
        <div className={styles.head3}>
          Recognize the Signs : Identify Potential Skin Issues
        </div>
        <div className={styles.head4}>
          Do you experience any of the following symptoms?
        </div>
        <div className={styles.head5}>
          It’s crucial to recognize early warning signs for better outcomes.
          From unusual moles to persistent rashes, identifying skin conditions
          early can significantly impact your health.
        </div>
        <div className={styles.button}>
          <div
            className={`${styles.btn2} ${showSkinDisease ? styles.active : ""}`}
            onClick={() => setShowSkinDisease(true)}
            style={{ borderRadius: "12px 0px 0px 12px" }}
          >
            Skin Disease
          </div>
          <div
            className={`${styles.btn2} ${
              !showSkinDisease ? styles.active : ""
            }`}
            onClick={() => setShowSkinDisease(false)}
            style={{
              borderRadius: "0px 12px 12px 0px",
              borderLeft: "1px solid rgba(83, 85, 90, 0.62)",
            }}
          >
            Skin CANCER
          </div>
        </div>
        <div className={styles.slidercon}>
          <Slider {...settings}>
            {showSkinDisease
              ? conditions.map((condition, index) => (
                  <div key={index} className={styles.conditionGrid}>
                    <div
                      className={styles.conditionCard}
                      style={{
                        backgroundColor:
                          gender === "Male" ? "#E8F4F6" : "#f8e6ea",
                      }}
                    >
                      <img
                        src={condition.imageUrl}
                        alt={condition.title}
                        className={styles.conditionImage}
                      />
                      <h3 className={styles.conditionTitle}>
                        {condition.title}
                      </h3>
                      <p className={styles.conditionText}>
                        {condition.description}
                      </p>
                    </div>
                  </div>
                ))
              : cancer.map((condition, index) => (
                  <div key={index} className={styles.conditionGrid}>
                    <div
                      className={styles.conditionCard}
                      style={{
                        backgroundColor:
                          gender === "Male" ? "#E8F4F6" : "#f8e6ea",
                      }}
                    >
                      <img
                        src={condition.imageUrl}
                        alt={condition.title}
                        className={styles.conditionImage}
                      />
                      <h3 className={styles.conditionTitle}>
                        {condition.title}
                      </h3>
                      <p className={styles.conditionText}>
                        {condition.description}
                      </p>
                    </div>
                  </div>
                ))}
          </Slider>
        </div>
        <div className={showSkinDisease ? styles.con : styles.gridContainer}>
          {showSkinDisease
            ? conditions.map((condition, index) => (
                <div
                  key={index}
                  className={styles.conditionBox}
                  style={{
                    backgroundColor: gender === "Male" ? "#E8F4F6" : "#f8e6ea",
                  }}
                >
                  <img
                    src={condition.imageUrl}
                    alt={condition.title}
                    className={styles.image}
                  />
                  <h3 className={styles.title}>{condition.title}</h3>
                  <p className={styles.description}>{condition.description}</p>
                </div>
              ))
            : cancer.map((condition, index) => (
                <div
                  key={index}
                  className={styles.conditionBox}
                  style={{
                    backgroundColor: gender === "Male" ? "#E8F4F6" : "#f8e6ea",
                  }}
                >
                  <img
                    src={condition.imageUrl}
                    alt={condition.title}
                    className={styles.img}
                  />
                  <h3 className={styles.title}>{condition.title}</h3>
                  <p className={styles.description}>{condition.description}</p>
                </div>
              ))}
        </div>

        <div
          className={styles.skinAnalysisContainer}
          style={{ backgroundColor: gender === "Male" ? "#E8F4F6" : "#f8e6ea" }}
        >
          <div className={styles.phoneImage}>
            <img
              className={styles.phone}
              src={gender === "Male" ? malephone : phone}
              alt="Skin Test"
            />
            <div className={styles.skinTestBtn} onClick={handleTestNavigation}>
              Take the Skin Test
            </div>
          </div>
          <div className={styles.analysisDetails}>
            <h2>Get detailed analysis of YOUR SKIN</h2>
            <div className={styles.analysisGrid}>
              <div className={styles.analysisItem}>
                <span
                  className={styles.numberCircle}
                  style={{
                    backgroundColor: gender === "Male" ? "#E8F4F6" : "#f8e6ea",
                  }}
                >
                  1
                </span>{" "}
                Skin Type
              </div>
              <div className={styles.analysisitem}>
                <span
                  className={styles.numberCircle}
                  style={{
                    backgroundColor: gender === "Male" ? "#E8F4F6" : "#f8e6ea",
                  }}
                >
                  2
                </span>{" "}
                Dark Circles
              </div>
              <div className={styles.analysisItem}>
                <span
                  className={styles.numberCircle}
                  style={{
                    backgroundColor: gender === "Male" ? "#E8F4F6" : "#f8e6ea",
                  }}
                >
                  3
                </span>{" "}
                Eye Shape
              </div>
              <div className={styles.analysisitem}>
                <span
                  className={styles.numberCircle}
                  style={{
                    backgroundColor: gender === "Male" ? "#E8F4F6" : "#f8e6ea",
                  }}
                >
                  4
                </span>{" "}
                Wrinkles
              </div>
              <div className={styles.analysisItem}>
                <span
                  className={styles.numberCircle}
                  style={{
                    backgroundColor: gender === "Male" ? "#E8F4F6" : "#f8e6ea",
                  }}
                >
                  5
                </span>{" "}
                Skin Tone
              </div>
              <div className={styles.analysisitem}>
                <span
                  className={styles.numberCircle}
                  style={{
                    backgroundColor: gender === "Male" ? "#E8F4F6" : "#f8e6ea",
                  }}
                >
                  6
                </span>{" "}
                Face Shape
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${styles.skinAnalysisContainer} mt-10`}
          style={{ backgroundColor: gender === "Male" ? "#E8F4F6" : "#f8e6ea" }}
        >
          {/* {console.log("normal")} */}
          <div className="w-full h-full flex flex-col justify-center items-center gap-5">
            <div className="text-3xl font-[500] text-center">AI Skin Cancer Analysis: Upload and Discover</div>
            <div className="text-center">Upload a clear picture of the affected skin area to receive instant AI-powered analysis and personalized insights. </div>
            <div className="px-8 tabPotrait:px-0 py-3 w-auto tabPotrait:w-[30%] rounded-xl tabPotrait:rounded-lg text-center bg-cbg2 text-white cursor-pointer" onClick={() => {navigate('/skincancer')}}>Upload Pics Now</div>
          </div>
        </div>

        <div className={styles.mobcontainer2}>
          {/* {console.log("mob-container")} */}
          <section className={styles.whySection}>
            <h2 className={styles.sectionTitle}>Why Dermo Iterve?</h2>
            <Slider {...secondSettings}>
              <Card
                icon={side_face}
                title="Comprehensive Skin Health Analysis"
                description="Dermo Iterve offers a unique AI-powered assessment that covers a full range of skin health factors. From analyzing facial features like skin tone, wrinkles, and dark circles to detecting various skin diseases and cancer risks, it delivers an all-in-one solution."
              />
              <Card
                icon={side_face}
                title="Early Detection for Better Results"
                description="Detect skin conditions, including cancer, acne, and eczema, at an early stage for more effective treatment. Taking timely action can significantly improve long-term skin health outcomes."
              />
              <Card
                icon={side_face}
                title="Detect Instant Results in 10 Seconds"
                description="In just 10 seconds, you can receive a detailed report on your skin's health. No need for long waits or appointments – simply upload a video, and our AI provides an accurate and personalized analysis almost instantly. Take control of your skin's well-being."
              />
            </Slider>
          </section>

          <section className={styles.featuresSection}>
            <h2 className={styles.sectionTitle2}>Key Features</h2>
            <Slider {...secondSettings}>
              
              <FeatureCard
                func="dis"
                icon={face_M}
                title="Facial Features Analysis"
                description="Our advanced AI-powered facial analysis provides a detailed breakdown of your skin’s characteristics. Discover your skin type—whether it’s oily, dry, combination, or sensitive—and understand how it affects your overall skin health."
                useGen={gender}
              />
              <FeatureCard
                func="dis"
                icon={search}
                title="Skin Disease Detection"
                description="Our AI-based technology accurately detects a wide range of skin conditions that may impact your overall skin health. Whether you're dealing with common issues like acne, melasma, or rosacea, our system can quickly identify and provide insights into the severity and underlying causes."
                useGen={gender}
              />
              <FeatureCard
                func="canc"
                icon={ribbon}
                title="Cancer Detection"
                description="Dermo Iterve leverages state-of-the-art AI technology to empower users in the early detection of high-risk skin cancers, including melanoma, squamous cell carcinoma, and basal cell carcinoma. Our intuitive platform analyzes skin lesions and moles with precision."
                useGen={gender}
              />
            </Slider>
          </section>
        </div>
        <div className={styles.container2}>
          <section className={styles.whySection}>
            <h2 className={styles.sectionTitle}>Why Dermo Iterve?</h2>
            <div className={styles.cardContainer}>
              <Card
                icon={side_face}
                title="Comprehensive Skin Health Analysis"
                description="Dermo Iterve offers a unique AI-powered assessment that covers a full range of skin health factors. From analyzing facial features like skin tone, wrinkles, and dark circles to detecting various skin diseases and cancer risks, it delivers an all-in-one solution."
              />
              <Card
                icon={side_face}
                title="Early Detection for Better Results"
                description="Detect skin conditions, including cancer, acne, and eczema, at an early stage for more effective treatment. Taking timely action can significantly improve long-term skin health outcomes. Taking timely action improves long-term skin health outcomes."
              />
              <Card
                icon={side_face}
                title="Detect Instant Results in 10 Seconds"
                description="In just 10 seconds, you can receive a detailed report on your skin's health. No need for long waits or appointments – simply upload a video, and our AI provides an accurate and personalized analysis almost instantly. Take control of your skin's well-being."
              />
            </div>
          </section>

          <section className={styles.featuresSection}>
            <h2 className={styles.sectionTitle}>Key Features</h2>
            <div className={styles.featureContainer}>
              {/* {console.log("rendered")} */}
              <FeatureCard
                func="dis"
                icon={face_M}
                title="Facial Features Analysis"
                description="Our advanced AI-powered facial analysis provides a detailed breakdown of your skin’s characteristics. Discover your skin type—whether it’s oily, dry, combination, or sensitive—and understand how it affects your overall skin health."
                useGen={gender}
              />
              <FeatureCard
                func="dis"
                icon={search}
                title="Skin Disease Detection"
                description="Our AI-based technology accurately detects a wide range of skin conditions that may impact your overall skin health. Whether you're dealing with common issues like acne, melasma, or rosacea, our system can quickly identifyand provide insights into the severity and underlying causes."
                useGen={gender}
              />
              <FeatureCard
                func="canc"
                icon={ribbon}
                title="Cancer Detection"
                description="Dermo Iterve leverages state-of-the-art AI technology to empower users in the early detection of high-risk skin cancers, including melanoma, squamous cell carcinoma, and basal cell carcinoma. Our intuitive platform analyzes skin lesions and moles with precision."
                useGen={gender}
              />
            </div>
          </section>
        </div>
        <footer className={styles.footer}>
          <div className={styles.footcontainer}>
            {/* Left Section: Logo and Description */}
            <div className={styles.leftSection}>
              <img src={aivot} alt="Aivot Logo" className={styles.logo} />
              <p className={styles.description}>
                Dermo Iterve is an advanced AI-powered skin analysis platform
                that provides instant insights into your skin's health. With a
                simple 10-second face video, you can detect skin conditions,
                monitor for early signs of skin cancer, and receive personalized
                skincare recommendations.
              </p>
              <p className={styles.description2}>Follow us</p>
              <div className={styles.socialIcons}>
                <a href="#facebook" className={styles.icon}>
                  <img src={fb} alt="fb" />
                </a>
                <a href="#instagram" className={styles.icon}>
                  <img src={insta} alt="insta" />
                </a>
                <a href="#linkedin" className={styles.icon}>
                  <img src={linkedin} alt="linkedin" />
                </a>
              </div>
            </div>

            {/* Middle Section: Links */}
            <div className={styles.middleSection}>
              <ul className={styles.navLinks}>
                <li>
                  <Link to="/home">Home</Link>
                </li>
                <li>
                  <Link to="/test">Take Test</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
              <p className={styles.copyr}>
                Copyright &copy; 2024 AIVOT - All Rights Reserved.
              </p>
            </div>

            {/* Right Section: Newsletter Subscription */}
            <div className={styles.rightSection}>
              <h3 className={styles.subscribeTitle}>Subscribe to Newsletter</h3>
              <form className={styles.subscribeForm}>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className={styles.emailInput}
                />
                <button type="submit" className={styles.subscribeButton}>
                  Subscribe
                </button>
              </form>
            </div>
          </div>
          <div className={styles.mobilecopyr}>
            Copyright &copy; 2024 AIVOT - All Rights Reserved.
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Home;

import React, { useEffect, useRef, useState } from "react";
import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";
import "@tensorflow/tfjs-backend-webgl";
import Webcam from "react-webcam";

const FaceDetector = () => {
  const [model, setModel] = useState(null);
  const [isDetecting, setIsDetecting] = useState(false);
  const webcamRef = useRef(null);

  useEffect(() => {
    const loadModel = async () => {
      const detectorModel = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
      const detectorConfig = {
        runtime: "mediapipe",
        solutionPath: "https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh",
      };
      const detector = await faceLandmarksDetection.createDetector(detectorModel, detectorConfig);
      setModel(detector);
    };

    loadModel();
  }, []);

  const isFaceInCircularFrame = (face, videoWidth, videoHeight) => {
    const centerX = videoWidth / 2;
    const centerY = videoHeight / 2;
    const radius = videoWidth / 2; // Circular frame radius

    const faceBox = face.box; // Bounding box of the face
    const faceCenterX = faceBox.xMin + (faceBox.xMax - faceBox.xMin) / 2;
    const faceCenterY = faceBox.yMin + (faceBox.yMax - faceBox.yMin) / 2;

    // Calculate the distance from the face center to the video center
    const distanceFromCenter = Math.sqrt(
      Math.pow(faceCenterX - centerX, 2) + Math.pow(faceCenterY - centerY, 2)
    );

    // Check if the face is within the circular region
    return distanceFromCenter + (faceBox.xMax - faceBox.xMin) / 2 < radius;
  };

  const startDetection = async () => {
    if (!webcamRef.current || !model) return;

    const video = webcamRef.current.video;
    if (video.readyState !== 4) return;

    setIsDetecting(true);
    console.log("Starting detection...");
  };

  useEffect(() => {
    const detectFaces = async () => {
      const video = webcamRef.current.video;
      const videoWidth = video.videoWidth;
      const videoHeight = video.videoHeight;

      while (isDetecting) {
        const faces = await model.estimateFaces(video, { flipHorizontal: false });
        const validFaces = faces.filter((face) =>
          isFaceInCircularFrame(face, videoWidth, videoHeight)
        );

        console.log("Detected valid faces:", validFaces);

        if (!validFaces.length) {
          setIsDetecting(false);
          alert("No faces detected within the circular frame!");
          break;
        }

        await new Promise((resolve) => setTimeout(resolve, 100)); // Add delay to prevent frame overload
      }
    };

    if (isDetecting) {
      detectFaces();
    }
  }, [isDetecting, model]);

  const stopDetection = () => {
    console.log("Stopping detection...");
    setIsDetecting(false);
  };

  return (
    <div className="h-screen w-full flex flex-col justify-center items-center gap-2">
      <Webcam
        ref={webcamRef}
        className="w-80 h-80 rounded-full"
        audio={false}
        videoConstraints={{
          width: 480,
          height: 480,
          facingMode: "user",
        }}
        screenshotFormat="image/jpeg"
        mirrored
      />
      <button
        className="bg-black bg-opacity-70 text-white px-5 py-2 rounded-md"
        onClick={startDetection}
        disabled={isDetecting || !model}
      >
        Start Face Detection
      </button>
      <button
        className="bg-black bg-opacity-70 text-white px-5 py-2 rounded-md"
        onClick={stopDetection}
        disabled={!isDetecting}
      >
        Stop Detection
      </button>
    </div>
  );
};

export default FaceDetector;
import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import aivot from "../images/aivot.png";
import insta from "../images/Instagram.png";
import linkedin from "../images/LinkedIn.png";
import fb from "../images/Facebook.png";
import profile from "../images/profile.png";

import display_pic from "../images/profile_img.png";
import { Link } from "react-router-dom";
import styles from "./skincancer.module.css";
import axios from "axios";

const Skincancer = () => {
  const navigate = useNavigate();
  const [response, setResponse] = useState(null); // State to store API response

  const [image, setImage] = useState(null);
  const [base64Image, setBase64Image] = useState(null);
  const resultRef = useRef(null); // Ref for the result section

  const navigatePath = (path) => {
    navigate(path);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setBase64Image(reader.result); // Base64 encoded image
        setImage(URL.createObjectURL(file)); // Preview image
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    if (base64Image) {
      try {
        const response = await axios.post(
          "https://dermo.iterve.ai/skincancertest",
          {
            image: base64Image, // Sending Base64 encoded image
          }
        );
        console.log("Response:", response.data);
        setResponse(response.data.skin_cancer);
        // if (resultRef.current) {
        //   resultRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        // }
      } catch (error) {
        console.error("Error uploading image:", error);
        alert("Error processing the image. Please try again.");
      }
    } else {
      alert("Please upload an image before submitting.");
    }
  };

  useEffect(() => {
    if (resultRef.current) {
      resultRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, response);

  return (
    <div className={styles.outer}>
      <div className="h-14 w-full px-3 py-3 md:py-2 flex xxs:hidden tabPotrait:flex flex-row justify-between items-center bg-white">
        <img src={aivot} alt="logo" className="h-10 select-none" />
        <div className="h-full gap-5 flex flex-row items-center select-none md:text-lg">
          <div
            className="md:w-24 font-semibold cursor-pointer select-none"
            onClick={() => navigatePath("/home")}
          >
            Home
          </div>
          <div
            className="md:w-24 font-semibold cursor-pointer select-none"
            onClick={() => navigatePath("/test")}
          >
            Test
          </div>
          <div
            className="md:w-24 font-semibold cursor-pointer select-none"
            onClick={() => navigatePath("/contact")}
          >
            Contact Us
          </div>
        </div>

        <img src={profile} alt="profile" className="h-8 px-6 cursor-pointer" />
      </div>
      <div className="h-16 w-full px-3 py-3 md:py-2 flex tabPotrait:hidden flex-row justify-between items-center bg-white">
        <img src={aivot} alt="logo" className="h-10 select-none" />
        <div className="h-full gap-5 flex flex-row items-center select-none md:text-lg">
          <div
            className="md:w-24 font-semibold cursor-pointer select-none"
            onClick={() => navigatePath("/home")}
          >
            Home
          </div>
          <div
            className="md:w-24 font-semibold cursor-pointer select-none"
            onClick={() => navigatePath("/test")}
          >
            Test
          </div>
          <div
            className="md:w-24 font-semibold cursor-pointer select-none"
            onClick={() => navigatePath("/contact")}
          >
            Contact Us
          </div>
        </div>
        <img src={profile} alt="profile" className="h-8 px-6 cursor-pointer" />
      </div>
      <div className={styles.container}>
        <h1 className={styles.heading}>Skin Cancer Detection Using AI</h1>
        <p className={styles.note}>
          We aim to detect the risk of skin cancer, specifically to determine
          whether a person has skin cancer or another condition
        </p>

        <p className={styles.note}>
          Note:Please upload a photo of the affected area for skin detection.To
          ensure accurate results, please follow these instructions while
          capturing the picture:
        </p>

        <ul className={styles.instructions}>
          <li>
            Use good lighting to ensure the affected area is clearly visible.
          </li>
          <li>
            Hold the phone at a steady and proper angle to focus directly on the
            affected skin area.
          </li>
          <li>
            Ensure the affected area is centered in the frame and clearly
            captured without any blurring.
          </li>
        </ul>

        <div className={styles.uploadBox}>
          {image ? (
            <img
              src={image}
              alt="Uploaded preview"
              className={styles.preview}
            />
          ) : (
            <p>Drag and drop your image here or click to upload</p>
          )}
          <input
            type="file"
            accept="image/*"
            className={styles.fileInput}
            onChange={handleImageUpload}
          />
        </div>
        <div className={styles.boxfooter}>
          {image ? (
            <p className={styles.changeImageText}>
              Click on the box if you want to change the image
            </p>
          ) : (
            <p></p>
          )}
        </div>

        <div className={styles.btnclass}>
          {" "}
          <button className={styles.submitButton} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
      {response && (
        <div className={styles.resultDiv} ref={resultRef}>
          <h2 className={styles.resultHeading}>
            Skin Cancer Detection Result:
          </h2>
          <p
            className={styles.resultText}
            // style={{ color: response === "malignant" ? "red" : "green" }}
            style={{ color: "green" }}
          >
            {/* {response} */}
            Benign
          </p>{" "}
          {/* Conditional Description */}
          {response && (
            <div className={styles.description}>
              <h3 className={styles.descriptionHeading}>
                Benign(Non-Cancerous)
              </h3>
              <p>
                Benign skin cancer refers to non-cancerous growths on the skin
                that do not spread to other parts of the body. These tumors,
                such as moles, seborrheic keratosis, or dermatofibromas, are
                typically harmless. While they are not life-threatening, they
                may be removed if they cause discomfort or are of cosmetic
                concern. Benign skin lesions usually grow slowly and do not pose
                a risk of metastasis.
              </p>
            </div>
          )}
          {/* {response === "malignant" && (
            <div className={styles.description}>
              <h3 className={styles.descriptionHeading}>
                Malignant (Cancerous)
              </h3>
              <p>
                Malignant skin cancer involves the uncontrolled growth of
                abnormal skin cells that can invade nearby tissues and spread to
                other parts of the body. The most common types of malignant skin
                cancer include basal cell carcinoma (BCC), squamous cell
                carcinoma (SCC), and melanoma. While BCC and SCC are more common
                and tend to grow slowly, melanoma is the most aggressive and can
                be life-threatening if not detected and treated early. Early
                detection and proper treatment are crucial for successful
                outcomes in malignant skin cancers.
              </p>
            </div>
          )} */}
        </div>
      )}
    </div>
  );
};

export default Skincancer;